.changePassPopupContainer {
    z-index: 1;
    width: 500px;
    background-color: var(--grey-5);
    border-radius: 10px;
}

.changePassHeadingContainer {
    display: flex;
    justify-content: space-between;
}

.changePassInputContainer {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.changePassButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.changePassButton {
    width: 100px;
    border-radius: 8px;
    background: var(--primary-4);
    color: var(--grey-5);
}

.changePassError {
    font-size: 14px;
    color: var(--danger-5);
    margin-bottom: 10px;
}