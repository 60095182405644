.callPopup .popupBody{
    padding: 0;
    overflow: auto;
    max-height: 100%;
}

.callPopup .popupContainer{
    width: 1000px;
}

.callPopup .closeWhite{
    position: absolute;
    top: 14px;
    right: 12px;
}

.callPopup table th{
    padding: 15px 20px;
    font-family: 'Inter Medium';
}