header{
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    z-index: 100;
    background: var(--white);
    padding: var(--spacing-20px) var(--spacing-40px);
    border-bottom: 2px solid var(--grey-3);
}
.headerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.headerContainer .logo{
    width: 150px;
}