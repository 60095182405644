.slotWrapper{
    width: calc(50% - 8px);
}

.slotWrapper label{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    padding: 12px 40px;
    font-size: 14px;
    color: var(--black-1);
    border-radius: 6px;
    border: 2px solid var(--grey-3);
}

.slotWrapper input{
    display: none;
}

.slotWrapper input:checked + label{
    background: var(--black-1);
    border-color: var(--black-1);
    color: var(--white);
}

.slotWrapper input:disabled + label{
    background: var(--grey-3);
}