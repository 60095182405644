.sideMenuWrap{
    min-width: 300px;
    background: var(--white);
    border-radius: 5px;
    overflow: hidden;
    padding: 25px;
    height: 100%;
}

.userDetail{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    position: relative;
}

.userDetail::before{
    content: '';
    width: 12px;
    height: 70px;
    background: #21222D;
    border-radius: 2px;
    position: absolute;
    left: -25px;
}

.textUser{
    font-size: 20px;
    line-height: 20px;
    font-family: 'inter semi bold';
}

.textUid{
    color: var(--black-5);
    font-size: 16px;
    line-height: 16px;
    font-family: 'Inter Light BETA';
}

.sideMenuList{
    list-style: none;
}

.sideMenuList li {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-radius: 10px;
    overflow: hidden;
    background: #F8F8F8;
    border-radius: 10px;
}

.sideMenuList li:not(:first-child){
    margin-top: 30px;
}

.sideMenuList li.active{
    background: #21222D;
    color: var(--white);
}

.sideMenuList li.active svg path{
    fill: var(--white);
}

.userImg{
    height: 70px;
    width: 70px;
    border-radius: 50px;
    object-fit:cover;
}