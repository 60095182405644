.multiMail{
    display: block;
    position: relative;
}

.multiMail span{
    display: block;
    width: 100%;
}

.multiMail span:not(:first-child){
    margin-top: 10px;
}

.callsTable th:nth-child(2){
    padding-left: 40px;
}