.headerSmall.withButton{
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerSmall.withButton + .mentorProfileContainer{
    height: calc(100% - 70px);
}

.cardDetail .changePass {
    display: flex;
    width: 136%;
    justify-content: space-between;
    align-items: flex-start;
}

.changePass span {
    font-size: 12px;
    color: var(--primary-5);
    cursor: pointer;
}

