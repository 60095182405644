.FeedbackCallWrapper{
    height: calc(100% - 70px);
}

.FeedbackCallWrapper .studentTable.active {
    visibility: visible;
    display: table;
    opacity: 1;
    transition: 0.4s;
}

.FeedbackCallWrapper .studentTable {
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: 0.4s;
}

.blueBgBtn{
    padding: 9px 20px;
    color: var(--primary-5);
    background: #FFFFFF;
    border: 1px solid var(--primary-5);
    border-radius: 10px;
    transition: 0.4s;
}

.blueBgBtn.active{
    background: #C2E0FA;
    transition: 0.4s;
}

.feedbackPreview{
    display: none;
}

.feedbackPreview.active{
    display: block;
}

.feedbackPreview table{
    width: 100%;
}

.feedbackData{
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
}

.feedbackPreview .dateAndTime{
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.nameData{
    padding-left: 10px;
    font-size: 12px;
    line-height: 22px;
    color: #525252;
}

.feedbackQuestion{
    padding: 30px;
}

.feedbackBox{
    display: block;
    position: relative;
    padding: 15px 30px;
    max-width: 630px;
    background: #FAFAFA;
    border-radius: 10px;
}

.feedbackBox:not(:first-child){
    margin-top: 20px;
}

.text-1{
    font-size: 18px;
    line-height: 120%;
}

.textAnswer{
    color: var(--primary-5);
}

.ratingBar{
    width: 100%;
    max-width: 380px;
    background: #E0EFFD;
    height: 5px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1px;
}

.ratingBarCount{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 380px;
    margin-top: 5px;
}

.ratingBar span{
    display: block;
    background: inherit;
    height: 100%;
    width: 100%;
}

.ratingBar span.active{
    background: var(--primary-5);

}