.mentorCallWrapper{
    height: calc(100% - 70px);
    overflow: auto;
}

.mentorCallWrapper .GreenSlotButton{
    flex-direction: row;
    padding: 10px 12px;
}

.mentorCallWrapper .GreenSlotButton:disabled{
    background: #F5F5F5;
    color: var(--grey-1);
}

.mentorCallWrapper .GreenSlotButton:disabled img{
    display: none;
}

.buttonGroupCalls{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
}

.buttonGroupCalls button{
    min-width: max-content;   
    font-size: 14px;
    transition: 0.4s; 
}

.greenBgBtn{
    padding: 9px 20px;
    color: var(--success-4);
    background: #FFFFFF;
    border: 1px solid #D7EFDF;
    border-radius: 10px;
}

.greenBgBtn.active{
    background: rgba(41, 158, 103, 0.08);
}

.redBgBtn{
    padding: 9px 20px;
    color: var(--danger-5);
    background: #FFFFFF;
    border: 1px solid #FDE5E5;
    border-radius: 10px;
}

.redBgBtn.active{
    background: #FFF2F2;
}

.mentorCallWrapper .studentTable{
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: 0.4s;
}

.mentorCallWrapper .studentTable.active{
    visibility: visible;
    display: table;
    opacity: 1;
    transition: 0.4s;
}