.button{
    background: transparent;
    color: black;
    width: 100%;
    font-size: 16px;
    line-height: 140%;
    font-family: 'Inter Regular';
    padding: 12px 15px;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
}

.blackButton{
    color: var(--white);
    background: var(--black-1);
    border-color: var(--black-1);
}

.whiteButton{
    color: var(--black-1);
    background: var(--white);
    border-color: var(--grey-3);
}

.blueButton{
    color: var(--white);
    background: var(--primary-5);
    border-color: var(--primary-5);
}

.iconButton{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.imgButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.imgButton img{
    display: block;
}

.outlineBtn{
    border: 1px solid var(--primary-5);
    color: var(--primary-5);
    font-size: 14px;
    line-height: 100%;
    background: var(--white);
}

.CallButton{
    padding: 16px;
    color: var(--grey-2);
    border: 2px solid var(--grey-2);
    background: var(--white);
    cursor: not-allowed;
}

.CallButton.active{
    color: var(--white);
    background: var(--primary-5);
    border-color: var(--primary-5);
    cursor: pointer;
}

.GreenSlotButton{
    background: var(--success-4);
    color: var(--white);
    flex-direction: row-reverse;
    padding: 10px 12px;
    max-width: max-content;
}

.GreenSlotBorder{
    background: var(--white);
    color: var(--success-4);
    border: 1px solid var(--success-4);
    padding: 8px 10px;
    max-width: max-content;
}