.popupHeaderInner{
    padding: 0;
}

.feedbackPopupWrap .feedbackQuestion{
    display: none;
    padding: 0;
}

.feedbackPopupWrap .feedbackQuestion.active{
    display: block;
}

.feedbackPopupWrap .popupBody{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.callTogglerPop .buttonGroupCalls{
    flex-wrap: wrap;
}

.feedbackPopupWrap  .feedbackBox{
    max-width: 100%;
}

.feedbackQuestionWrap{
    width: 100%;
}

.callTogglerPop{
    max-width: 200px;
}
