.appContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 62px);
    overflow: auto;
    margin-top: 62px;
    padding: 10px;
    background: var(--grey-4);
    gap: 10px;
}

.routesContainer {
    width: calc(100% - 290px);
}

.sectionAdjust{
    /* width: calc(100% - 300px); */
    width: 100%;
    height: 100%;
}